<template>
  <div class="dp-top">
    <DpPageLoading v-if="isLoading" :is-loading="isLoading" fluid />

    <div :style="{ opacity: !isLoading ? 1 : 0 }">
      <div
        v-if="carouselContents && carouselContents.length"
        class="dp-top-pro-recipe">
        <h1 class="dp-top-pro-recipe__title">
          {{ WORDS.DRIP_POD_TOP.PRO_RECIPE.TITLE }}
        </h1>
        <p class="dp-top-pro-recipe__subtitle">
          {{ WORDS.DRIP_POD_TOP.PRO_RECIPE.SUBTITLE }}
        </p>

        <ProRecipeCarousel :contents="carouselContents">
          <div
            v-for="content in carouselContents"
            :key="content.index"
            class="swiper-slide">
            <ProRecipeCarouselCard
              :content="content"
              @on-img-loaded="onHeadCarouselImgLoaded" />
          </div>
        </ProRecipeCarousel>

        <ul class="dp-top-pro-recipe__buttons">
          <li>
            <DpTextButton
              :href="$customUrlScheme.dp(`/pro-recipe`)"
              align="right"
              fluid>
              <template v-slot:iconAppend>
                <Icon name="dpArrowRightB" color="dpBlack01" />
              </template>
              {{ WORDS.DRIP_POD_TOP.PRO_RECIPE.LINK_PRO_RECIPE }}
            </DpTextButton>
          </li>
          <li>
            <DpButton
              :href="$customUrlScheme.dp(`/capsule`)"
              style-type="secondary"
              fluid>
              {{ WORDS.DRIP_POD_TOP.PRO_RECIPE.LINK_CAPSULE }}
            </DpButton>
          </li>
        </ul>
      </div>

      <div class="dp-top-sections">
        <DpTopSection
          :contents="myrecipeContents"
          :title-en="WORDS.DRIP_POD_TOP.MYRECIPE.TITLE_EN"
          :title-jp="WORDS.DRIP_POD_TOP.MYRECIPE.TITLE_JP"
          :link-to="$customUrlScheme.dp(`/brew/my-recipe`)">
          <ProRecipeSmallCarousel
            v-if="myrecipeContents && myrecipeContents.length">
            <div
              v-for="content in myrecipeContents"
              :key="content.index"
              class="swiper-slide">
              <ProRecipeSmallCarouselCard :content="content" />
            </div>
          </ProRecipeSmallCarousel>

          <template
            v-else-if="myrecipeContents && myrecipeContents.length === 0">
            <div class="dp-top-empty">
              <DpEmpty
                icon-name="dpBookmarkOffR"
                :text="WORDS.DRIP_POD_MYRECIPE_LIST.EMPTY_TEXT"
                :subtext="WORDS.DRIP_POD_MYRECIPE_LIST.EMPTY_SUBTEXT" />
            </div>

            <DpButton
              :href="$customUrlScheme.dp(`/pro-recipe`)"
              style-type="secondary"
              fluid>
              {{ WORDS.DRIP_POD_TOP.PRO_RECIPE.LINK_PRO_RECIPE }}
            </DpButton>
          </template>
        </DpTopSection>

        <DpTopSection
          :contents="historyContents"
          :title-en="WORDS.DRIP_POD_TOP.HISTORY.TITLE_EN"
          :title-jp="WORDS.DRIP_POD_TOP.HISTORY.TITLE_JP"
          :link-to="$customUrlScheme.dp(`/brew/history`)">
          <ProRecipeSmallCarousel
            v-if="historyContents && historyContents.length">
            <div
              v-for="content in historyContents"
              :key="content.index"
              class="swiper-slide">
              <ProRecipeSmallCarouselCard :content="content" />
            </div>
          </ProRecipeSmallCarousel>

          <template v-else-if="historyContents && historyContents.length === 0">
            <div class="dp-top-empty">
              <DpEmpty
                icon-name="dpHistoryR"
                :text="WORDS.DRIP_POD_HISTORY_LIST.EMPTY_TEXT"
                :subtext="WORDS.DRIP_POD_HISTORY_LIST.EMPTY_SUBTEXT" />
            </div>

            <DpButton
              :href="$customUrlScheme.dp(`/pro-recipe`)"
              style-type="secondary"
              fluid>
              {{ WORDS.DRIP_POD_TOP.PRO_RECIPE.LINK_PRO_RECIPE }}
            </DpButton>
          </template>
        </DpTopSection>

        <DpTopSection
          :title-en="WORDS.DRIP_POD_TOP.FAVORITE.TITLE_EN"
          :title-jp="WORDS.DRIP_POD_TOP.FAVORITE.TITLE_JP"
          :is-secondary="true">
          <p class="dp-top-lead">
            {{ WORDS.DRIP_POD_TOP.FAVORITE.LEAD }}
          </p>

          <FavoriteRecipeCard
            v-if="isActiveBluetooth && favoriteContent"
            :content="favoriteContent" />

          <div v-else class="dp-top-favorite-empty">
            <div class="dp-top-empty__inner">
              <p class="dp-top-favorite-empty__title">
                {{
                  isActiveBluetooth
                    ? WORDS.DRIP_POD_TOP.FAVORITE.EMPTY_TITLE
                    : WORDS.DRIP_POD_TOP.FAVORITE.INACTIVE_BLUETOOTH_TITLE
                }}
              </p>
              <p class="dp-top-favorite-empty__lead">
                {{
                  isActiveBluetooth
                    ? WORDS.DRIP_POD_TOP.FAVORITE.EMPTY_TEXT
                    : WORDS.DRIP_POD_TOP.FAVORITE.INACTIVE_BLUETOOTH_TEXT
                }}
              </p>
              <DpButton
                :href="
                  isActiveBluetooth
                    ? $customUrlScheme.dp('/favorite-overview')
                    : $customUrlScheme.page('blt-status-unconnect')
                "
                style-type="secondary"
                fluid>
                {{
                  isActiveBluetooth
                    ? WORDS.DRIP_POD_TOP.FAVORITE.EMPTY_LINK_LABEL
                    : WORDS.DRIP_POD_TOP.FAVORITE.INACTIVE_BLUETOOTH_LINK_LABEL
                }}
              </DpButton>
            </div>
          </div>
        </DpTopSection>

        <!-- <DpTopSection
          :title-en="WORDS.DRIP_POD_TOP.HOW_TO_USE.TITLE_EN"
          :title-jp="WORDS.DRIP_POD_TOP.HOW_TO_USE.TITLE_JP">
          <p class="dp-top-lead">
            {{ WORDS.DRIP_POD_TOP.HOW_TO_USE.LEAD }}
          </p>
          <div class="dp-top-video-player">
            <video
              ref="video"
              class="dp-top-video-player__video"
              controls
              playsinline>
              <source
                src="/assets/video/content/study/movie01.mp4"
                type="video/mp4" />
              {{ WORDS.DRIP_POD_TOP.MOVIE_UNSUPPORTED }}
            </video>
          </div>
        </DpTopSection> -->

        <DpTopSection v-if="linkContents && linkContents.length">
          <Banner
            v-for="content in linkContents"
            :key="content.id"
            :href="$contentList.getLink(content.link_url)"
            :src="content.image_url"
            :alt="content.title" />
        </DpTopSection>
      </div>

      <DpMenu />
    </div>
  </div>
</template>

<script>
import { WORDS } from '@/constants/words';
import { SystemDialogMessage } from '@/constants/enums';
import { usePublishable } from '@/composables/usePublishable';

import {
  defineComponent,
  ref,
  computed,
  watch,
  onBeforeUnmount,
  onMounted
} from '@vue/composition-api';

export default defineComponent({
  components: {
    DpPageLoading: () => import('@/components/dripPod/core/PageLoading.vue'),
    DpMenu: () => import('@/components/dripPod/top/Menu.vue'),
    ProRecipeCarousel: () =>
      import('@/components/dripPod/top/ProRecipeCarousel.vue'),

    ProRecipeCarouselCard: () =>
      import('@/components/dripPod/top/ProRecipeCarouselCard.vue'),

    DpTopSection: () => import('@/components/dripPod/top/Section.vue'),
    ProRecipeSmallCarousel: () =>
      import('@/components/dripPod/top/ProRecipeSmallCarousel.vue'),

    ProRecipeSmallCarouselCard: () =>
      import('@/components/dripPod/top/ProRecipeSmallCarouselCard.vue'),

    DpButton: () => import('@/components/dripPod/core/Button.vue'),
    DpTextButton: () => import('@/components/dripPod/core/TextButton.vue'),

    FavoriteRecipeCard: () =>
      import('@/components/dripPod/top/FavoriteRecipeCard.vue'),

    DpEmpty: () => import('@/components/dripPod/core/Empty.vue')
  },

  setup: (props, context) => {
    const { getPublishableContents } = usePublishable();
    const carouselContents = ref(undefined);
    const myrecipeContents = ref([]);
    const historyContents = ref([]);
    const favoriteContent = ref(undefined);
    const linkContents = ref(undefined);
    const loadedHeadCarouselImgCount = ref(0);

    const isActiveBluetooth = ref(false);
    const favoriteRecipeId = ref(undefined);
    const video = ref(undefined);

    const sleep = (ms = 400) =>
      new Promise((resolve) => setTimeout(resolve, ms));

    const isLoading = computed(
      () => loadedHeadCarouselImgCount.value !== carouselContentsLength.value
    );

    const carouselContentsLength = computed(
      () => carouselContents.value?.length || 0
    );

    const onHeadCarouselImgLoaded = async () => {
      await sleep();
      loadedHeadCarouselImgCount.value++;
    };

    const carouselBannerContents = [
      {
        name: WORDS.DRIP_POD_TOP.PRO_RECIPE.LINK_CAPSULE,
        url: context.root.$customUrlScheme.dp('/capsule'),
        banner: '/assets/img/drip-pod/top/banner_carousel01.webp'
      }
    ];

    const fetchList = async () => {
      try {
        const { data } = await context.root.$repositories('dpTop').getList();
        const publishableContents = getPublishableContents(data?.recipes);
        carouselContents.value = publishableContents.concat(
          carouselBannerContents
        );
        myrecipeContents.value = data?.bookmarks || [];
        historyContents.value = data?.histories || [];
        linkContents.value = data?.links;
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      }
    };

    const fetchFavoriteRecipe = async () => {
      if (!favoriteRecipeId.value) {
        favoriteContent.value = undefined;
        return;
      }

      try {
        const { data } = await context.root
          .$repositories('dpProRecipe')
          .getById(favoriteRecipeId.value);
        favoriteContent.value = data;
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      }
    };

    const fetchData = async () => {
      context.root.$_loading_start();
      await Promise.all([fetchList(), fetchFavoriteRecipe()]).then(() => {});
    };

    watch(isLoading, () => {
      if (!isLoading.value) context.root.$_loading_stop();
      else context.root.$_loading_start();
    });

    /**
     * DP本体に登録されているレシピのIDと接続状況を取得する
     * recipe_id: 0の場合は登録がない、
     * @param {Object} {is_connecting: boolean, recipe_id: number}
     */
    const getFavoriteRecipeId = (params) => {
      isActiveBluetooth.value = params.is_connecting;
      favoriteRecipeId.value = params.recipe_id;

      fetchFavoriteRecipe();
    };

    // NOTE: ネイティブ側からインスタンスにアクセスするためにwindowにバインドしておく
    window.view = { getFavoriteRecipeId };

    onBeforeUnmount(() => {
      video.value.paused;
    });

    onMounted(async () => {
      fetchData();
    });

    return {
      WORDS,
      SystemDialogMessage,
      isActiveBluetooth,
      isLoading,

      carouselContents,
      myrecipeContents,
      historyContents,
      favoriteContent,
      linkContents,
      onHeadCarouselImgLoaded,
      video
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-top {
  @include mixin.dpDefaultText;

  padding-bottom: 60px;
  min-height: 200vh;
  background-color: variables.$dpWhite01;
}

.dp-top-lead {
  margin-top: -8px;
  margin-bottom: 24px;
}

.dp-top-pro-recipe {
  padding: 16px 16px 32px;
  background-color: variables.$dpGreyF1;

  &__title {
    @include mixin.dpEnTitle;

    margin-bottom: 4px;
    font-size: 32px;
    line-height: 1.25;
    text-transform: capitalize;
  }

  &__subtitle {
    margin-bottom: 16px;
  }

  &__buttons {
    z-index: 1;
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    row-gap: 20px;
  }
}

.dp-top-empty {
  margin-bottom: 32px;

  &__inner {
    padding: 32px 16px;
    background-color: variables.$dpWhite01;
  }
}

.dp-top-favorite-empty {
  &__title {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 1.3;
    font-weight: bold;
    text-align: center;
    white-space: pre-wrap;
  }

  &__lead {
    margin-bottom: 32px;
  }
}

.dp-top-video-player::v-deep {
  @include mixin.imageAspectRatio(343, 193);

  line-height: 1;

  &__video {
    width: 100%;
    height: 100%;
  }
}
</style>
