var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dp-top" },
    [
      _vm.isLoading
        ? _c("DpPageLoading", {
            attrs: { "is-loading": _vm.isLoading, fluid: "" },
          })
        : _vm._e(),
      _c(
        "div",
        { style: { opacity: !_vm.isLoading ? 1 : 0 } },
        [
          _vm.carouselContents && _vm.carouselContents.length
            ? _c(
                "div",
                { staticClass: "dp-top-pro-recipe" },
                [
                  _c("h1", { staticClass: "dp-top-pro-recipe__title" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.WORDS.DRIP_POD_TOP.PRO_RECIPE.TITLE) +
                        " "
                    ),
                  ]),
                  _c("p", { staticClass: "dp-top-pro-recipe__subtitle" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.WORDS.DRIP_POD_TOP.PRO_RECIPE.SUBTITLE) +
                        " "
                    ),
                  ]),
                  _c(
                    "ProRecipeCarousel",
                    { attrs: { contents: _vm.carouselContents } },
                    _vm._l(_vm.carouselContents, function (content) {
                      return _c(
                        "div",
                        { key: content.index, staticClass: "swiper-slide" },
                        [
                          _c("ProRecipeCarouselCard", {
                            attrs: { content: content },
                            on: {
                              "on-img-loaded": _vm.onHeadCarouselImgLoaded,
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c("ul", { staticClass: "dp-top-pro-recipe__buttons" }, [
                    _c(
                      "li",
                      [
                        _c(
                          "DpTextButton",
                          {
                            attrs: {
                              href: _vm.$customUrlScheme.dp("/pro-recipe"),
                              align: "right",
                              fluid: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "iconAppend",
                                  fn: function () {
                                    return [
                                      _c("Icon", {
                                        attrs: {
                                          name: "dpArrowRightB",
                                          color: "dpBlack01",
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3194498299
                            ),
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.WORDS.DRIP_POD_TOP.PRO_RECIPE
                                    .LINK_PRO_RECIPE
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      [
                        _c(
                          "DpButton",
                          {
                            attrs: {
                              href: _vm.$customUrlScheme.dp("/capsule"),
                              "style-type": "secondary",
                              fluid: "",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.WORDS.DRIP_POD_TOP.PRO_RECIPE.LINK_CAPSULE
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "dp-top-sections" },
            [
              _c(
                "DpTopSection",
                {
                  attrs: {
                    contents: _vm.myrecipeContents,
                    "title-en": _vm.WORDS.DRIP_POD_TOP.MYRECIPE.TITLE_EN,
                    "title-jp": _vm.WORDS.DRIP_POD_TOP.MYRECIPE.TITLE_JP,
                    "link-to": _vm.$customUrlScheme.dp("/brew/my-recipe"),
                  },
                },
                [
                  _vm.myrecipeContents && _vm.myrecipeContents.length
                    ? _c(
                        "ProRecipeSmallCarousel",
                        _vm._l(_vm.myrecipeContents, function (content) {
                          return _c(
                            "div",
                            { key: content.index, staticClass: "swiper-slide" },
                            [
                              _c("ProRecipeSmallCarouselCard", {
                                attrs: { content: content },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm.myrecipeContents && _vm.myrecipeContents.length === 0
                    ? [
                        _c(
                          "div",
                          { staticClass: "dp-top-empty" },
                          [
                            _c("DpEmpty", {
                              attrs: {
                                "icon-name": "dpBookmarkOffR",
                                text: _vm.WORDS.DRIP_POD_MYRECIPE_LIST
                                  .EMPTY_TEXT,
                                subtext:
                                  _vm.WORDS.DRIP_POD_MYRECIPE_LIST
                                    .EMPTY_SUBTEXT,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "DpButton",
                          {
                            attrs: {
                              href: _vm.$customUrlScheme.dp("/pro-recipe"),
                              "style-type": "secondary",
                              fluid: "",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.WORDS.DRIP_POD_TOP.PRO_RECIPE
                                    .LINK_PRO_RECIPE
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "DpTopSection",
                {
                  attrs: {
                    contents: _vm.historyContents,
                    "title-en": _vm.WORDS.DRIP_POD_TOP.HISTORY.TITLE_EN,
                    "title-jp": _vm.WORDS.DRIP_POD_TOP.HISTORY.TITLE_JP,
                    "link-to": _vm.$customUrlScheme.dp("/brew/history"),
                  },
                },
                [
                  _vm.historyContents && _vm.historyContents.length
                    ? _c(
                        "ProRecipeSmallCarousel",
                        _vm._l(_vm.historyContents, function (content) {
                          return _c(
                            "div",
                            { key: content.index, staticClass: "swiper-slide" },
                            [
                              _c("ProRecipeSmallCarouselCard", {
                                attrs: { content: content },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm.historyContents && _vm.historyContents.length === 0
                    ? [
                        _c(
                          "div",
                          { staticClass: "dp-top-empty" },
                          [
                            _c("DpEmpty", {
                              attrs: {
                                "icon-name": "dpHistoryR",
                                text: _vm.WORDS.DRIP_POD_HISTORY_LIST
                                  .EMPTY_TEXT,
                                subtext:
                                  _vm.WORDS.DRIP_POD_HISTORY_LIST.EMPTY_SUBTEXT,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "DpButton",
                          {
                            attrs: {
                              href: _vm.$customUrlScheme.dp("/pro-recipe"),
                              "style-type": "secondary",
                              fluid: "",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.WORDS.DRIP_POD_TOP.PRO_RECIPE
                                    .LINK_PRO_RECIPE
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "DpTopSection",
                {
                  attrs: {
                    "title-en": _vm.WORDS.DRIP_POD_TOP.FAVORITE.TITLE_EN,
                    "title-jp": _vm.WORDS.DRIP_POD_TOP.FAVORITE.TITLE_JP,
                    "is-secondary": true,
                  },
                },
                [
                  _c("p", { staticClass: "dp-top-lead" }, [
                    _vm._v(
                      " " + _vm._s(_vm.WORDS.DRIP_POD_TOP.FAVORITE.LEAD) + " "
                    ),
                  ]),
                  _vm.isActiveBluetooth && _vm.favoriteContent
                    ? _c("FavoriteRecipeCard", {
                        attrs: { content: _vm.favoriteContent },
                      })
                    : _c("div", { staticClass: "dp-top-favorite-empty" }, [
                        _c(
                          "div",
                          { staticClass: "dp-top-empty__inner" },
                          [
                            _c(
                              "p",
                              { staticClass: "dp-top-favorite-empty__title" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.isActiveBluetooth
                                        ? _vm.WORDS.DRIP_POD_TOP.FAVORITE
                                            .EMPTY_TITLE
                                        : _vm.WORDS.DRIP_POD_TOP.FAVORITE
                                            .INACTIVE_BLUETOOTH_TITLE
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "p",
                              { staticClass: "dp-top-favorite-empty__lead" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.isActiveBluetooth
                                        ? _vm.WORDS.DRIP_POD_TOP.FAVORITE
                                            .EMPTY_TEXT
                                        : _vm.WORDS.DRIP_POD_TOP.FAVORITE
                                            .INACTIVE_BLUETOOTH_TEXT
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "DpButton",
                              {
                                attrs: {
                                  href: _vm.isActiveBluetooth
                                    ? _vm.$customUrlScheme.dp(
                                        "/favorite-overview"
                                      )
                                    : _vm.$customUrlScheme.page(
                                        "blt-status-unconnect"
                                      ),
                                  "style-type": "secondary",
                                  fluid: "",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.isActiveBluetooth
                                        ? _vm.WORDS.DRIP_POD_TOP.FAVORITE
                                            .EMPTY_LINK_LABEL
                                        : _vm.WORDS.DRIP_POD_TOP.FAVORITE
                                            .INACTIVE_BLUETOOTH_LINK_LABEL
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                ],
                1
              ),
              _vm.linkContents && _vm.linkContents.length
                ? _c(
                    "DpTopSection",
                    _vm._l(_vm.linkContents, function (content) {
                      return _c("Banner", {
                        key: content.id,
                        attrs: {
                          href: _vm.$contentList.getLink(content.link_url),
                          src: content.image_url,
                          alt: content.title,
                        },
                      })
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("DpMenu"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }